@import "../../assets/scss/variable.scss";

/*--------------------------------------------*/
/* Case Study */
/*--------------------------------------------*/
.case-study {
    position: relative;
    overflow: hidden;
}
.case-study .case-study-image {
    background-size: cover;
    background-position: center center;
    min-height: 570px;
    border-radius: $radius-5;
}
.case-study .case-study-title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: $title-color;
    text-transform: uppercase;
    text-decoration: none;
}
.case-study .case-study-category {
    display: block;
    color: $primary-color;
    margin-bottom: 10px;
}
.case-study .icon-btn {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    text-align: center;
    background: $primary-color;    
    color: $white-color;
    font-size: 12px;
    text-decoration: none;
    border-radius: $radius-50;
}

.case-study.masonry:nth-child(2) .case-study-image,
.case-study.masonry:nth-child(3) .case-study-image {
    min-height: 270px;
}

.case-study.masonry:nth-child(1) {
    grid-column: 1/2;
    grid-row: 1/3;
}
.case-study.masonry:nth-child(4) {
    grid-column: 3;
    grid-row: 1/3;
}

/*--------------------------------------------*/
/* Case Study : Style 1 */
/*--------------------------------------------*/
.case-study.case-study-style-01 .case-study-info {
    padding: 10px;
    width: 90%;
    position: absolute;
    left: 50%;
    bottom: -90px;
    z-index: 2;
    opacity: 0;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    background: #ffffff;
}
.case-study.case-study-style-01:hover .case-study-info {
    opacity: 1;
    bottom: 20px;
}

.case-study.case-study-style-02:hover .case-title{
    display: none !important;
}

/*--------------------------------------------*/
/* Case Study : Style 2 */
/*--------------------------------------------*/
.case-study.case-study-style-02 .case-study-info {
    padding: 20px;
    width: 90%;
    position: absolute;
    left: 50%;
    bottom: -90px;
    z-index: 2;
    opacity: 0;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    background: transparent;
}
.case-study.case-study-style-02:hover .case-study-info {
    opacity: 1;
    bottom: 20px;
}

.case-study.case-study-style-02:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    top: auto;
    opacity: 0.8;
    background: #000;
    transition: $transition-3;
    border-radius: $radius-5;
}
.case-study.case-study-style-02:hover::before {
    bottom: 0;
    height: 100%;
}

.case-title{
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    padding: 20px;
    font-weight: 600;
    // background: rgba(0,0,0,0.1);
    background-image: url('../../assets/images/blurry.svg');
    text-transform: uppercase;
    width: 100%;
    font-size: 25px;
    @media only screen and (max-width:991px) {
        font-size: 20px;
    }
    @media only screen and (max-width:800px) {
        font-size: 18px;
    }
    @media only screen and (max-width:600px) {
        font-size: 15px;
    }
}

.case-study.case-study-style-02 .case-study-title,
.case-study.case-study-style-02 p {
    color: $white-color;
}

/*--------------------------------------------*/
/* Case Study : Style 3 */
/*--------------------------------------------*/
.case-study.case-study-style-03 .case-study-image {
    min-height: inherit;
    overflow: hidden;
    border-radius: $radius-5;
}
.case-study.case-study-style-03 .case-study-image img {
    max-width: 100%;
    transform: scale(1);
    transition: $transition-3;
}
.case-study.case-study-style-03:hover .case-study-image img {
    transform: scale(1.1);
}
.case-study.case-study-style-03 .case-study-info {
    margin-top: 20px;
}
.case-study.case-study-style-03 .case-study-category {
    margin-bottom: 0;
}
.case-study.case-study-style-03.masonry {
    grid-column: inherit;
    grid-row: inherit;
}

/*--------------------------------------------*/
/* Case Study Single */
/*--------------------------------------------*/
.case-studies-single .clients-detail {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    background: #F5F7FA;
    padding: 45px;
    margin-top: 15px;
    border-radius: 12px;
}
.case-studies-single .clients-detail label {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;
    color: $title-color;
}
.case-studies-single .clients-detail li a {
    color: $primary-color;
}
.case-studies-single .clients-detail li a:hover {
    color: $title-color;
}

/*--------------------------------------------*/
/* Responsive View */
/*--------------------------------------------*/
@media (max-width: 1199px) {
  
}

@media (max-width: 991px) {
    
    .case-studies-single .clients-detail {flex-wrap: wrap; gap: 25px 30px;}
    .case-studies-single .clients-detail li {width: calc(50% - 15px);}
}
  
@media (max-width: 767px) {

    .case-study.masonry:nth-child(1) {grid-column: inherit; grid-row: inherit;}
    .case-study.masonry:nth-child(4) {grid-column: inherit; grid-row: inherit;}
    
}

@media (max-width: 575px) {
    
    .case-studies-single .clients-detail {gap: 25px 0px;}
    .case-studies-single .clients-detail li {width: 100%;}
}