@import "../assets/scss/variable.scss";

.widget {
    margin-bottom: 50px;
}
.widget:last-child {
    margin-bottom: 0px;
}

.widget .widget-title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 25px;
}

/*----------------------------------------------*/
/* Search */
/*----------------------------------------------*/
.widget-search .search-form {
    position: relative;
}
.widget-search .search-form input {
    width: 100%;
    border: none;
    outline: none;
    padding: 15px 20px;
    padding-right: 60px;
    border-radius: 7px;
    color: $white-color;
    background: $primary-color;
}
.widget-search .search-form button {
    position: absolute;
    right: 5px;
    background: none;
    border: none;
    height: 100%;
    width: 50px;
    padding: 0;
    color: $white-color;
}

.widget-search .search-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white-color;
    opacity: 0.7;
}
.widget-search .search-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $white-color;
    opacity: 0.7;
}
.widget-search .search-form input::-ms-input-placeholder { /* Microsoft Edge */
    color: $white-color;
    opacity: 0.7;
}

/*----------------------------------------------*/
/* Useful links */
/*----------------------------------------------*/
.widget .footer-link {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.widget .footer-link li {
    margin-bottom: 8px;
}
.widget .footer-link li:last-child {
    margin-bottom: 0;
}
.widget .footer-link  li a {
    color: inherit;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
.widget .footer-link  li a:hover {
    color: $primary-color;
}

.footer-menu .footer-link {
    display: grid;
    width: 100%;
}
.footer-menu .footer-link.col-1 { grid-template-columns: repeat(1, 1fr); }
.footer-menu .footer-link.col-2 { grid-template-columns: repeat(1, 1fr); }
.footer-menu .footer-link.col-3 { grid-template-columns: repeat(3, 1fr); }

/*----------------------------------------------*/
/* Social List */
/*----------------------------------------------*/
.widget .social-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    gap: 5px 25px;
    list-style-type: none;
}
.widget .social-list li a {
    padding: 0px;
    font-size: 18px;
    color: $title-color;
    transition: all 0.2s ease-in-out;
}
.widget .social-list li a:hover {
    color: $primary-color;
}

/* Theme Color */
.widget .social-list.primary li a {color: $primary-color;}
.widget .social-list.primary li a:hover {color: $title-color;}

.widget .social-list.dark li a {color: $dark-color;}
.widget .social-list.dark li a:hover {color: $primary-color;}

.widget .social-list.light li a {color: $white-color;}
.widget .social-list.light li a:hover {color: $primary-color;}

/* Icon Size */
.widget .social-list.icon-xl a i {font-size: 3em;}
.widget .social-list.icon-lg a i {font-size: 2em;}
.widget .social-list.icon-sm a {font-size: 16px;}

/* Icon Gap */
.widget .social-list.grid-gap-5 {gap: 5px 5px;}
.widget .social-list.grid-gap-10 {gap: 10px 10px;}
.widget .social-list.grid-gap-15 {gap: 15px 15px;}
.widget .social-list.grid-gap-20 {gap: 10px 20px;}
.widget .social-list.grid-gap-25 {gap: 15px 25px;}
.widget .social-list.grid-gap-30 {gap: 15px 30px;}
.widget .social-list.grid-gap-35 {gap: 15px 35px;}
.widget .social-list.grid-gap-40 {gap: 15px 40px;}
.widget .social-list.grid-gap-45 {gap: 20px 45px;}
.widget .social-list.grid-gap-50 {gap: 20px 50px;}

/*----------------------------------------------*/
/* Contact Info */
/*----------------------------------------------*/
.widget .info-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.widget .info-list li {
    display: flex;
    gap: 15px;
    margin-bottom: 12px;
    align-items: baseline;
}
.widget .info-list li:last-child {
    margin-bottom: 0;
}
.widget .info-list li i {
    color: $primary-color;
}
.widget .info-list li a {
    color: inherit;
}
.widget .info-list li a:hover {
    color: $primary-color;
}

.widget.contact-info-style-02 .info-list li label {
    min-width: 70px;
}
.widget.contact-info-style-02 .info-list li .phone-number {
    font-size: 24px;
    color: $title-color;
    font-weight: 700;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 767px) {
    .widget .social-list.icon-lg a i {
        font-size: 24px;
    }
}

@media only screen and (max-width: 575px) {
    .footer-menu .footer-link.col-2 {
        grid-template-columns: repeat(1, 1fr);
    }
    .widget .social-list {
        gap: 20px 30px;
    }
}