@import '../../assets/scss/_variable.scss';

.event-map {
	@media (max-width: 575px) {
		margin-bottom: 30px;
	}
	@media only screen and (min-width: 576px) and (max-width: 767px) {
		margin-bottom: 30px;
	}
	@media only screen and (min-width: 768px) and (max-width: 991px) {
		margin-bottom: 30px;
	}
	iframe {
		height: 330px;
		width: 100%;
	}
}

//event select style
.event-select select {
	width: 100%;
	height: 55px;
	padding: 0 20px;
	outline: none;
	border: 0;
	background: #fff;
	border-radius: 4px;
}
.event-select {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        border-bottom: 2px solid $primary-color;
        border-right: 2px solid $primary-color;
        transform: rotate(45deg);
        width: 8px;
        height: 8px;
        right: 22px;
        top: 22px;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        padding-left: 15px;
    }
}
.find-event-input.calendar input {
	color: $primary-color;
}

/*----------

26. contact style

----------*/

// contact-style

.contact-from-input input {
	height: 60px;
	width: 100%;
	background: #f8f8f9;
	border: none;
	padding: 15px 20px;
	border-radius: 4px;
	outline: 0;
}

.mb-10{
	margin-bottom: 40px;
}

.contact-from-input textarea {
	height: 190px;
	width: 100%;
	background: #f8f8f9;
	border: none;
	padding: 15px 20px;
	border-radius: 4px;
	resize: none;
	outline: 0;
}
.support-item {
	&:last-child {
		margin-bottom: 0;
	}
	display: flex;
	align-items: flex-start;
	gap: 20px;
	margin-bottom: 30px;
	@media (max-width: 575px) {
		gap: 12px;
	}
}
.support-contact {
	padding: 25px 30px 25px 30px;
	background: #f8f8f9;
	border-radius: 5px;
	border: 1px solid #f8f8f9;
	@media (max-width: 575px) {
		padding: 25px 20px 25px 20px;
	}
}
.support-tittle {
	h4 {
		margin-bottom: 30px;
	}
}
.support-info-phone {
	span {
		font-size: 18px;
		font-weight: 700;
		color: #000;
		margin-bottom: 10px;
		display: block;
	}
	p, div {
		margin-bottom: 3px;
		a {
			color: #707070;
			// font-weight: 600;
		}
	}
}

.contact-btn{
	background-color: $primary-color;
	border-radius: 5px;
	padding: 10px 15px;
	text-align: center;
	color: $white-color;
	border: 1px solid $primary-color;
	transition: all;
	transition-duration: 400ms;
	transition-timing-function: ease-in-out;
}

.contact-btn:hover{
	background-color: white;
	color: $primary-color;
}

.support-info-email {
	span {
		font-size: 18px;
		font-weight: 700;
		color:#000;
		margin-bottom: 10px;
		display: block;
	}
}
.support-info-email {
	a {
		margin-bottom: 2px;
		display: block;
        color: #707070;
	}
}
.support-info-location {
	width: 80%;
	span {
		font-size: 18px;
		font-weight: 700;
		color: #000;
		margin-bottom: 10px;
		display: block;
	}
    a {
        color: #707070;
	}
}
.singel-brand {
	text-align: center;
}
// nice-select
.contact-select .nice-select {
	height: 60px;
	width: 100%;
	background: #f8f8f9;
	border: none;
	padding: 15px 20px;
	line-height: 30px;
	span {
		font-size: 14px;
		color: #333333;
	}
	&:after {
		width: 7px;
		height: 7px;
		right: 20px;
	}
}
.contact-select .nice-select .list {
	width: 100%;
	background-color: #f8f8f9;
}

.contact-map iframe {
	height: 368px;
	// object-fit: cover;
	width: 100%;
	border-radius: 4px;
}
