@import '../../assets/scss/variable.scss';

.topbar {
  padding: 2px 30px;
  background-color: #f6f8fb;
  border-bottom: 1px solid rgba(2, 45, 98, 0.1);
  // border-bottom: 1px solid rgba(2, 45, 98, 0.1);
}
.topbar-inner {
  display: flex;

  .top-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 5px 50px;
    list-style-type: none;

    span {
      font-size: 12px;
      margin-top: 1px;
    }
    .month-name {
      text-transform: capitalize;
    }
  }

  .top-info li i {
    margin-right: 12px;
    color: $primary-color;
  }

  .top-info li a {
    text-decoration: none;
    font-family: $body-font;
    font-weight: 400;
    font-size: 12px;
    line-height: 33px;
    color: #000;
    transition: all 0.3s ease-in-out;
    @media screen and (max-width: 768px) {
      font-size: 10px;
      line-height: 20px;
    }
    &:hover {
      color: #000;
    }
  }
}

.topbar-inner .top-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 5px 10px;
  margin-left: auto;
  list-style-type: none;

  li {
    cursor: pointer;
  }

  li a {
    font-family: $body-font;
    font-weight: 600;
    font-size: 14px;
    color: $title-color;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $primary-color;
    }
  }
}

.active-lang img {
  border: 1px solid #333;
  border-radius: 2px;
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.menu {
  position: absolute;
  right: 0px;
  top: 32px;
  border-top: 5px solid $primary-color;
  z-index: 9999;
  border-radius: 8px;
  list-style-type: none;
  background-color: #f4f4f4;
  margin: 5px 0;
  padding: 0;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  width: 80px;
}

.menu > li {
  margin: 0;
  background-color: #f4f4f4;
  border-radius: 8px;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 12px;
  background: none;
  color: inherit;
  border: none;
  padding: 2px 0;
  margin: 0;
  cursor: pointer;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 1199px) {
  .topbar {
    padding: 0px 25px;
  }
}
@media only screen and (max-width: 800px) {
  .top-menu {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .topbar {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .topbar {
    padding-left: 10px;
    padding-right: 10px;
  }
  .topbar-inner {
    flex-wrap: wrap;
  }
  .topbar-inner .top-info,
  .topbar-inner .top-menu {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}
