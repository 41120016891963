/*=====================================================*/
/* Section Margin */
/*=====================================================*/
.section-mtb {margin-top: 100px;  margin-bottom: 100px;}
.section-mt {margin-top: 100px;}
.section-mb {margin-bottom: 100px;}

.section-sm-mtb {margin-top: 70px; margin-bottom: 70px;}
.section-sm-mt {margin-top: 70px;}
.section-sm-mb {margin-bottom: 70px;}

.section-xs-mtb {margin-top: 50px; margin-bottom: 50px;}
.section-xs-mt {margin-top: 50px;}
.section-xs-mb {margin-bottom: 50px;}

/*=====================================================*/
/* Section Padding */
/*=====================================================*/
.section-ptb {padding-top: 100px;  padding-bottom: 100px;}
.section-pt {padding-top: 100px;}
.section-pb {padding-bottom: 100px;}

.page-ptb {padding-top: 30px;  padding-bottom: 100px;}

.section-sm-ptb {padding-top: 70px; padding-bottom: 70px;}
.section-sm-pt {padding-top: 70px;}
.section-sm-pb {padding-bottom: 70px;}

.section-xs-ptb {padding-top: 50px; padding-bottom: 50px;}
.section-xs-pt {padding-top: 50px;}
.section-xs-pb {padding-bottom: 50px;}


.content-wrapper.page-template {padding-top: 30px; padding-bottom: 70px;}
.content-wrapper.no-padding {padding: 0;}

/*=====================================================*/
/* Grid Col - CSS */
/*=====================================================*/
.grid-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
}

.grid-justify-center {justify-items: center;}
.grid-align-center {align-items: center;}

.grid-gap-50 {grid-gap: 50px 30px;}
.grid-gap-30 {grid-gap: 30px 30px;}
.grid-gap-15 {grid-gap: 15px 30px;}
.grid-gap-10 {grid-gap: 10px;}
.grid-gap-5 {grid-gap: 5;}
.grid-gap-0 {grid-gap: 0;}

.grid-wrapper.grid-lg-1 { grid-template-columns: repeat(1, 1fr); }
.grid-wrapper.grid-lg-2 { grid-template-columns: repeat(2, 1fr); }
.grid-wrapper.grid-lg-3 { grid-template-columns: repeat(3, 1fr); }
.grid-wrapper.grid-lg-4 { grid-template-columns: repeat(4, 1fr); }
.grid-wrapper.grid-lg-5 { grid-template-columns: repeat(5, 1fr); }
.grid-wrapper.grid-lg-6 { grid-template-columns: repeat(6, 1fr); }

@media only screen and (max-width: 1200px) {
    .grid-wrapper.grid-c-1 { grid-template-columns: repeat(1, 1fr); }
    .grid-wrapper.grid-c-2 { grid-template-columns: repeat(2, 1fr); }
    .grid-wrapper.grid-c-3 { grid-template-columns: repeat(3, 1fr); }
    .grid-wrapper.grid-c-4 { grid-template-columns: repeat(4, 1fr); }
    .grid-wrapper.grid-c-5 { grid-template-columns: repeat(5, 1fr); }
    .grid-wrapper.grid-c-6 { grid-template-columns: repeat(6, 1fr); }
}

@media only screen and (max-width: 991px) {
    .grid-wrapper.grid-md-1 { grid-template-columns: repeat(1, 1fr); }
    .grid-wrapper.grid-md-2 { grid-template-columns: repeat(2, 1fr); }
    .grid-wrapper.grid-md-3 { grid-template-columns: repeat(3, 1fr); }
    .grid-wrapper.grid-md-4 { grid-template-columns: repeat(4, 1fr); }
    .grid-wrapper.grid-md-5 { grid-template-columns: repeat(5, 1fr); }
    .grid-wrapper.grid-md-6 { grid-template-columns: repeat(6, 1fr); }
}

@media only screen and (max-width: 767px) {
    .grid-wrapper.grid-sm-1 { grid-template-columns: repeat(1, 1fr); }
    .grid-wrapper.grid-sm-2 { grid-template-columns: repeat(2, 1fr); }
    .grid-wrapper.grid-sm-3 { grid-template-columns: repeat(3, 1fr); }
    .grid-wrapper.grid-sm-4 { grid-template-columns: repeat(4, 1fr); }
    .grid-wrapper.grid-sm-5 { grid-template-columns: repeat(5, 1fr); }
    .grid-wrapper.grid-sm-6 { grid-template-columns: repeat(6, 1fr); }
}

@media only screen and (max-width: 575px) {
    .grid-wrapper.grid-xs-1 { grid-template-columns: repeat(1, 1fr); }
    .grid-wrapper.grid-xs-2 { grid-template-columns: repeat(2, 1fr); }
    .grid-wrapper.grid-xs-3 { grid-template-columns: repeat(3, 1fr); }
    .grid-wrapper.grid-xs-4 { grid-template-columns: repeat(4, 1fr); }
    .grid-wrapper.grid-xs-5 { grid-template-columns: repeat(5, 1fr); }
    .grid-wrapper.grid-xs-6 { grid-template-columns: repeat(6, 1fr); }
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 991px) {

    .section-ptb {padding-top: 70px;  padding-bottom: 70px;}
    .section-pt {padding-top: 70px;}
    .section-pb {padding-bottom: 70px;}

    .section-sm-ptb {padding-top: 50px; padding-bottom: 50px;}
    .section-sm-pt {padding-top: 50px;}
    .section-sm-pb {padding-bottom: 50px;}
    
    .content-wrapper.page-template {padding-top: 70px; padding-bottom: 70px;}
    
}

@media only screen and (max-width: 767px) {

    .section-ptb {padding-top: 50px;  padding-bottom: 50px;}
    .section-pt {padding-top: 50px;}
    .section-pb {padding-bottom: 50px;}

    .content-wrapper.page-template {padding-top: 50px; padding-bottom: 50px;}
}