@import '../../assets/scss/variable.scss';

.edu-webinar {
    display: flex;
    gap: 25px;
    padding: 40px;
    background-color: #F6F8FB;
    border-radius: 10px;
    margin-bottom: 20px;
    @media (max-width: 575px) {
		flex-direction: column;
	}
    @media only screen and (min-width: 576px) and (max-width: 767px) {
		flex-direction: column;
	}
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
		flex-direction: column;
	}
    @media only screen and (min-width: 1200px) and (max-width: 1399px) {
		flex-direction: column;
	}
    &-img {
        min-width: 230px;
        width: 230px;
        position: relative;
        @media only screen and (min-width: 1200px) and (max-width: 1399px) {
            width: 100%;
        }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
            width: 100%;
        }
        @media only screen and (min-width: 576px) and (max-width: 767px) {
            width: 100%;
        }
        @media (max-width: 575px) {
            width: 100%;
        }
        img {
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }
    &-text {
        &-title {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
            line-height: 1.25;
            a{
                color: $primary-color;
            }
            &:hover {
                color: $primary-color;
            }
        }
        p{
            margin: 20px 0;
        }
        ul {
            display: flex;
            gap: 20px;
            padding-left: 0 !important;
            margin-bottom: 10px;
            li {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #575757;
                i {
                    color: $primary-color;
                    margin-right: 5px;
                    transform: translateY(-1px);
                }
            }
        }
    }
    .webinar-badge {
        position: absolute;
        top: 15px;
        left: 0px;
        padding: 0px 10px;
        font-size: 12px;
        font-weight: 600;
        background: $primary-color;
        display: inline-block;
        color: $white-color;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .get-btn {
        height: 48px;
        padding: 10px 25px;
        line-height: 40px;
        background: none;
        color: $primary-color;
        border: 1px solid $primary-color;
        &:hover {
            background: $primary-color;
            color: $white-color;
        }
    }
}

.edu-webinar-desc{
    height: 100px;
    overflow: hidden;
    margin-bottom: 20px;
}

.course-webinar-video {
    .course-video-thumb {
        position: relative;
        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: rgba(0,0,0,.7);
        }
    }
}