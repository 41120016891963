/*=====================================================*/
/* General - CSS */
/*=====================================================*/
.custom-sticky {
  top: 125px;
  z-index: 1;
}
.color-inherit,
.color-inherit:hover {
  color: inherit;
}
.modal-backdrop {
  --bs-backdrop-opacity: 0.8;
}

/*=====================================================*/
/* Border Radius */
/*=====================================================*/
.border-radius {
	border-radius: $radius-3;
}
.border-radius-5 {
	border-radius: $radius-5;
}
.border-radius-7 {
	border-radius: $radius-7;
}
.border-radius-12 {
	border-radius: $radius-12;
}
.border-radius-30 {
	border-radius: $radius-30;
}
.border-radius-50 {
	border-radius: $radius-50;
}
.border-radius-80 {
	border-radius: $radius-80;
}
.border {
  border-color: $border-color !important;
}

/*=====================================================*/
/* Text Color */
/*=====================================================*/
.text-primary {
  color: $primary-color !important;
}
.text-secondary {
  color: $secondary-color !important;
}
.text-dark {
  color: $dark-color !important;
}
.text-light {
  color: $light-color !important;
}
.text-white {
  color: $white-color !important;
}

/*=====================================================*/
/* Background Color */
/*=====================================================*/
.bg-primary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-dark {
  background-color: $dark-color !important;
}
.bg-light {
  background-color: $light-color !important;
}
.bg-white {
  background-color: $white-color !important;
}

/*=====================================================*/
/* Background Overlay Color */
/*=====================================================*/
.bg-overlay-primary,
.bg-overlay-secondary,
.bg-overlay-black,
.bg-overlay-white {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.bg-overlay-primary:before {background-color: $primary-color;}
// .bg-overlay-secondary:before {background-color: $secondary-color;}
.bg-overlay-black:before {background-color: $dark-color;}
.bg-overlay-white:before {background-color: $white-color;}

.bg-overlay-primary:before,
.bg-overlay-secondary:before,
.bg-overlay-black:before,
.bg-overlay-white:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0.9;
}
.overlay-10:before {opacity: 0.1;}
.overlay-20:before {opacity: 0.2;}
.overlay-30:before {opacity: 0.3;}
.overlay-40:before {opacity: 0.4;}
.overlay-50:before {opacity: 0.5;}
.overlay-60:before {opacity: 0.6;}
.overlay-70:before {opacity: 0.7;}
.overlay-80:before {opacity: 0.8;}
.overlay-90:before {opacity: 0.9;}

/*=====================================================*/
/* Swiper Slider */
/*=====================================================*/


/* Swiper Custom Arrow */
.swiper .swiper-button-prev:after,
.swiper .swiper-button-next:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  color: $secondary-color;
  opacity: 1;
  transition: $transition;
  padding: 10px 10px;
  border-radius: 50%;
  background-color: #fff;
}
.swiper .swiper-button-prev:after {
  content:"\2039";
}
.swiper .swiper-button-next:after {
  content:"\203A";
}
.swiper .swiper-button-prev:hover:after,
.swiper .swiper-button-next:hover:after {
  opacity: 1;
}

.show-all{
	color: $primary-color;
}

/* Swiper Arrow Light */
.swiper.swiper-slider-light .swiper-button-prev:after,
.swiper.swiper-slider-light .swiper-button-next:after {
  color: $white-color;
}

.swiper{
  width: 100% !important;
}

/*=====================================================*/
/* List Item */
/*=====================================================*/
.list-item {
  display: grid;
  grid-gap: 5px 15px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.list-item li {
  font-size: 15px;
  line-height: 28px;
  color: $dark-color;
}

[data-bg-height="200"]:before {height: 200px;}
[data-bg-height="250"]:before {height: 250px;}
[data-bg-height="300"]:before {height: 300px;}
[data-bg-height="350"]:before {height: 350px;}
[data-bg-height="400"]:before {height: 400px;}
[data-bg-height="450"]:before {height: 450px;}
[data-bg-height="500"]:before {height: 500px;}
[data-bg-height="550"]:before {height: 550px;}
[data-bg-height="600"]:before {height: 600px;}

/*=====================================================*/
/* Main Banner */
/*=====================================================*/
.banner-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1181/445!important
}
.banner-item {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 10%;
  position: relative;
  width: 100%;
}

.banner-content {
  text-align: left !important;
  color: $primary-color;
  width: 50%;
  text-align: center;
}
.swiper-slide .play-button{
  z-index: 99;
  width: 50%;
  text-align: center;
  .image-container{
    
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0000003B;;
    img{
      cursor: pointer;
    }
  }
}
.banner-item .title {
  font-family: $title-font;
  color: $primary-color;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 20px;
}
.banner-item h6 {
  color: #333;
  font-size: 25px;
}

.banner-content .text-stroke-white {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  display: block;
}
.banner-content .font-large {
  font-size: 86px;
  line-height: 100px;
}

.banner-section .banner-content .btn-secondary {
  margin-top: 15px;
  background-color: $white-color;
  color: #000;
}

@media only screen and (max-width: 992px) {
  .banner-content{
    margin-left: 10%;
  }
}



/* Banner Slider Arrow */
.swiper .swiper-button-prev {left: 2px;}
.swiper .swiper-button-next {right:2px;}
.banner-section .swiper .swiper-button-prev,
.banner-section .swiper .swiper-button-next {
  display: flex;
  width: 40px;
  height: 40px;
  text-align: center;
  opacity: 1;
  background: #fff;
  border: none;
  outline: none;
  border-radius: 25% !important;
  box-shadow: 0px 5px 20px 0px #B8CCC04D !important;
}
.banner-section .swiper .swiper-button-prev:after,
.banner-section .swiper .swiper-button-next:after {
  font-size: 16px;
  border-radius: 25% !important;
}
.banner-section .swiper .swiper-button-prev:hover,
.banner-section .swiper .swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-next::after{
  color: $primary-color !important;
  border-radius: 25% !important;
}

.swiper-button-prev::after{
  color: $primary-color !important;
  border-radius: 25% !important;
}


.swiper-pagination{
  position: absolute !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	color:#fff;
	width: 40px !important;
	border-radius: 5px !important;
	background: $primary-color !important;

}

.swiper-pagination-bullet {
   	padding: 7px 7px;
  	border-radius: 50% ;
  	margin: 0 5px !important;
	width: 10px !important;
	height: 5px !important;
  	font-size: 5px !important;
	text-align: center;
	line-height: 30px;
	font-size: 12px !important;
	opacity: 1;
	background: #838383 !important ;
}

.about-list li{
  color: #333;
  font-weight: 600;
}

.current-event-box {
	overflow: hidden;
	@media (max-width: 575px) {
		margin-bottom: 30px;
	}
	&:hover{
		box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
	}
}

.event-date-wrapper{
	background-color: #fff;
}

.event-date {
	height: 80px;
	width: 90px;
	margin: auto;
	background: $primary-color;
	display: flex;
	text-align: center;
	justify-content: center;
	line-height: 60px;
	font-size: 36px;
	font-weight: 700;
	color: #fff;
}
.event-month {
	background: $primary-color !important;
	display: flex;
	border-top: 1px solid #fff;
	height: 100%;
	width: 90px;
	margin: auto;
	justify-content: center;
	text-align: center;
	line-height: 40px;
	font-size: 15px;
	color: #000;
	div{
		color: #fff;
	}
}

.current-event-date {
	gap: 10px;
	@media (max-width: 575px) {
		flex-direction: column;
	}
}

.event-tour {
	background: $white-color;
	border: 1px solid #f6f8fb;
	display: flex;
	justify-content: space-between;
	width: 100%;
	min-width: 80%;
	padding: 14px 20px 14px 20px;
	align-items: center;
	// flex-wrap: no-wrap;
	gap: 10px;
	@media (max-width: 575px) {
		padding: 18px 10px 16px 10px;
		width: 100%;
	}
}

.event-box-text {
	h3 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 0px ;
		line-height: 1;
    a{
      color: #000;
    }
	}
	span {
		font-size: 14px;
		font-weight: 600;
		margin-right: 20px;
		i {
			margin-right: 5px;
		}
	}

}

.event-arrow {
	color: #777;

	height: 45px;
	width: 45px;
	background-color: #f6f6f6;
	text-align: center;
	line-height: 45px;
	&:hover {
		color: $white-color;
		background: $primary-color;
	}
}

.competition-container{
	display: flex;
	gap: 40px;
	overflow: hidden;
	position: relative;
	justify-content: space-between;
	width: 100%;

	@media only screen and (max-width: 1462px) {
		flex-direction: column;
	}
}


.feedback-header {
	display: flex;
	margin-bottom: 40px;
	align-items: center;
}
.feedback-icon {
	i {
		position: absolute;
		top: 30px;
		right: 30px;
		font-size: 72px;
		color: #ced5e5;
		z-index: -1;
	}
}

.feedback-active{
	width: 80%;
	margin: auto;
	height: calc(100% - 150px);
	@media only screen and (max-width: 1462px) {
		width: 100%;
	}
}

.events-container{
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.feedback-body {
	padding-bottom: 20px;
	h3 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 10px;
		color: #000;
	}
	p{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		white-space: pre-wrap;
		-webkit-line-clamp: 3;
		overflow: hidden;
		height: fit-content;
	}
}

.gap-40{
	margin-left: 40px;
}
.feedback-content {
	height: 100%;
	
	@media only screen and (min-width: 768px) and (max-width: 991px) {
		margin-left: 0px;
	}
	@media only screen and (min-width: 576px) and (max-width: 767px) {
		margin-left: 0px;
	}
	@media (max-width: 575px) {
		margin-left: 0px;
	}
}

.feedback-items {
	background: $white-color;
	height: 100%;
	background-color: #fff;
	margin: 0 40px;
	z-index: 2;
	border-radius: 15px;
	padding: 10px;
	
	& .feedback-text{
		margin: 30px 40px !important;
	}
}

.feedback-container{
	width: 50%;
	@media only screen and (max-width:1450px) {
		width: 100%;
	}
}

.feedback-title {
	margin-left: 20px;
	h4 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 3px;
	}
}


.text-left-important h2{
  text-align: left !important;
}

.course-category{
  background: $primary-color;
  color: #fff;
  border-radius: 5px;
  padding: 2px 10px;
}

.eduman-course-wraper {
	padding: 30px;
	background: $white-color;
}
.my-course-info {
	h3 {
		margin-bottom: 25px;
	}
}

.cart-info-body h4{
	margin: 5px 0;
}
.eduman-course-main-wrapper {
	box-shadow: 0px 20px 40px rgba(24, 44, 74, 0.05);
	border-radius: 10px;
	position: relative;
}
.eduman-course-img {
	overflow: hidden;
	border-radius: 10px;
}
.eduman-course-img img {
	transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
}
.eduman-course-main-wrapper:hover .eduman-course-img img {
	height: 2;
	transform: scale3d(1.05, 1.05, 1.05);
}
.eduman-course-main-wrapper:hover .course-cart {
	opacity: 1;
	visibility: visible;
}

.rc__title{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
}

.course-cart {
	background: $white-color;
	padding: 30px 40px;
	box-shadow: 0px 0px 70px rgba(15, 38, 71, 0.2);
	min-width: 90% !important;
	position: absolute;
	opacity: 0;
	visibility: hidden;
	top: 0;
	right: 0;
	transition: 0.3s;
	z-index: 1;
	border-radius: 5px;
	left: 50%;
	max-height: 98%;
	height: 98%;
	overflow: hidden;
	transform: translateX(-50%);
	@media only screen and (min-width: 768px) and (max-width: 991px) {
		padding: 30px 30px;
		min-width: 340px;
	}
	@media only screen and (min-width: 992px) and (max-width: 1199px) {
		padding: 20px 20px;
		min-width: 340px;
	}
	@media only screen and (min-width: 1200px) and (max-width: 1399px) {
		padding: 30px 30px;
		min-width: 340px;
	}
	@media (max-width: 575px) {
		display: none;
	}
}

.course-info-wrapper{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.course-info-wrapper, .course-info-body, .cart-level, .course-desc-c, .course-desc{
	height: 100%;
	position: relative;
}

.course-desc{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: pre-wrap;
	-webkit-line-clamp: 9;
	overflow: hidden;
	height: fit-content;
}


.course-action{ 
	margin-top: 15px	;
	a{
		background-color: $primary-color;
		color: $white-color;
		border-radius: 5px;
		padding: 10px 15px;
	
	}
}

.eduman-course-thumb {
	border-radius: 10px;
	overflow: hidden;
}
.eduman-course-main-wrapper:hover .eduman-course-thumb img {
	transform: scale3d(1.05, 1.05, 1.05);
}
.eduman-course-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}
.eduman-course-text {
	height: 90px;
	display: flex;
	align-items: center;
	h3 {
		font-size: 20px;
		line-height: 1.5;
    a{
      color: #000;
    }
		a:hover {
			color: $primary-color;
		}
		@media (max-width: 575px) {
			font-size: 18px;
		}
		@media only screen and (min-width: 576px) and (max-width: 767px) {
			font-size: 18px;
		}
		@media only screen and (min-width: 768px) and (max-width: 991px) {
			font-size: 18px;
		}
		@media only screen and (min-width: 992px) and (max-width: 1199px) {
			font-size: 18px;
		}
	}
}

.eduman-course-price {
	margin-bottom: 25px;
	span {
		color: #000;
		font-size: 18px;
		font-weight: 800;
		margin-right: 5px;
		margin-bottom: 5px;
	}
}
.eduman-course-tutor {
	font-size: 15px;
	font-weight: 600;
  a{
    color: #000;
  }
	a:hover {
		color: $primary-color;
	}
	img {
		margin-right: 10px;
	}
}
.eduman-course-footer {
	display: flex;
	justify-content: space-between;
	padding: 10px 30px;
	border-top: 1px solid #b4b4b4;
	position: relative;
	background-color: $white-color;
	border-radius: 0px 0px 10px 10px;
}

.course-deteals-btn {
	a {
		color:$primary-color;
		&:hover {
			color: $primary-color;
		}
		span {
			font-weight: 700;
			font-size: 15px;
			@media only screen and (min-width: 992px) and (max-width: 1199px) {
				font-size: 14px;
			}
			@media (max-width: 575px) {
				font-size: 14px;
			}
		}
	}
}
.course-lessson-svg {
	span {
		font-size: 15px;
	}
	&::before {
		position: absolute;
		height: 100%;
		width: 1px;
		background: #edeef2;
		content: "";
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
	}
}


/*-----------------------*/
/* Overlay Home Banner */
/*-----------------------*/
.overlay-home-banner {
  height: 750px;
  padding-top: 150px;
}
.banner-logo {
  display: inline-block;
  padding: 10px 20px;
  background: $primary-color;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 240px;
  flex: 0 0 240px;
  color: $white-color;
  font-size: 70px;
  line-height: 60px;
  position: relative;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 20px;
  text-align: center;
  border-radius: $radius-7;
}
.banner-logo:before {
  position: absolute;
  content: "";
  width: 30px;
  height: 21px;
  left: -30px;
  border-top: 20px solid rgba(239, 49, 57, 0);
  border-right: 20px solid $primary-color;
  top: 15px;
}
.banner-list {
  padding: 0;
  margin: 30px 0 50px;
  list-style-type: none;
  text-align: center;
}
.banner-list li {
  display: inline-block;
}

.sidebar__widget-title{
	font-size: 20px;
	margin-bottom: 25px;
}

.banner-list li a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  border-right: 2px solid #ffffff;
  padding: 0 15px;
}

.edu-six-btn{
  background-color: #fff;
  color: $primary-color;
  border-radius: 5px;
  font-weight: 600;
  padding: 8px 10px;
  transition: all;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out;
}

.about__post-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.sidebar__tag {
	& a {
		display: inline-block;
		height: 36px;
		line-height: 38px;
		padding: 0 15px;
		background: #F6F8FB !important;
		border-radius: 4px;
		margin-right: 8px;
		margin-bottom: 4px;
		&:hover {
			color: #fff;
			background: $primary-color !important;
		}
	}
}

.membership-price{
  gap: 10px;
}

.know-us-wrapper {
	position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  background-color: #fff;
  margin-top: 30px;
  padding: 20px 0 10px;
}

.know-us-text {
	min-height: 100px;
  display: flex;
  align-items: center;
}
.know-us-text {
	h3 {
		color: #000;
		font-size: 18px;
    font-weight: 400;
    font-family: 'Rubik';
    padding-top: 15px;
		z-index: 3;
		position: relative;
	}
}
.know-us-wrapper {
	overflow: hidden;
	border-radius: 5px;
	img {
    transform: translateY(8px);
		transition: all 1.5s cubic-bezier(0, 0, 0.2, 1);
	}
}
.know-us-wrapper:hover .know-us-better-thumb img {
	transform: scale3d(1.1, 1.1, 1.1);
}

.know-us-tittle{
  color: #363636 !important;
}

.sidebar-widget-wrapper {
	margin-left: 20px;
  position: sticky;
  top: 100px;

	@media only screen and (min-width: 992px) and (max-width: 1199px) {
		margin-left: 0;
	}
	@media only screen and (min-width: 768px) and (max-width: 991px) {
		margin-left: 0;
	}
	@media only screen and (min-width: 576px) and (max-width: 767px) {
		margin-left: 0;
	}
	@media (max-width: 575px) {
		margin-left: 0;
	}
}

.sidebar-widget {
	border: 1px solid $primary-color;
	border-radius: 5px;
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	background-color: #fff;
	max-width: 510px;
	@media (max-width: 575px) {
		max-width: 100%;
	}
}
.sidebar-widget-inner {
	padding: 35px 30px 30px 30px;
	height: 385px;
}

.rc__thumb.mr-20 img {
	width: 70px !important;
	aspect-ratio: '16:9';
  margin-right: 20px;
}

.rc__title a{
  color: #000;
  max-height: 56px;
}

.rc__meta{
	font-size: 13px;
}

.sidebar__widget {
	border: 1px solid #f2f2f4;
	padding: 30px 30px;
	border-radius: 5px;
	background-color: #f6f8fb;
	box-shadow: 0px 10px 30px rgba(28, 51, 84, 0.08);
	
	@media (max-width: 575px) {
		padding: 30px 20px;
	}
}

.contact-page .sidebar__widget{
	background-color: rgba(248, 248, 249, 1);
	box-shadow: none;
}

.sidebar__category ul li:not(:last-child) {
  margin-bottom: 15px;
}

.sidebar__category ul li a{
  font-size: 16px;
  font-weight: 600;
  color: #000;
  padding-left: 20px;
  position: relative;
}

.sidebar__category ul li a::after{
  position: absolute;
  content: "";
  left: 0;
  top: 9px;
  width: 6px;
  height: 6px;
  background: #adaeba;
  border-radius : 50%;
}

.sidebar__category ul li a:hover{
  color: $primary-color;
}

.sidebar__category ul li a:hover:after{
  background: $primary-color;
}

.sidebar__tag a {
	display: inline-block;
	height: 36px;
	line-height: 38px;
	padding: 0 15px;
	font-size: 15px;
	font-weight: 600;
	color: #000;
	background: #fff;
	border-radius : 4px;
	margin-right: 8px;
	margin-bottom: 10px;
}

.sidebar__tag a:hover {
  color: #fff;
  background: $primary-color;
}

.testimonial-header {
	display: flex;
	align-items: center;
	margin-bottom: 40px;
}
.testimonial-area{
  background-color: #f6f6f6 !important;
  margin-top: 20px;
  cursor: grab;
}
.testimonial-items {
	background: #fff;
	padding: 20px 20px;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	z-index: 2;
	border-radius: 5px;
  cursor: pointer;
}

.testimonial-title {
	h4 {
		font-size: 16px;
		font-weight: 700;
	}
	span {
		font-size: 14px;
		margin-bottom: 2px;
		display: block;
	}
}
.testimoni-quotes {
	img {
		position: absolute;
		top: 30px;
		right: 30px;
		z-index: -1;
		content: "";
	}
}
.testimonial-body {
	font-size: 18px;
  font-family: 'Rubik';
  font-weight: 400;
  color: #000;
}
.testimonial-icon {
	i {
		color: #000;
		font-size: 15px;
	}
}
.testimonial-pagination {
	margin-top: 50px;
	line-height: 1;
}

.category-icon{
  margin: 0 10px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 4px 10px 0px #022D621A !important;
  margin-top: 10px;
  img{
    height: 130px;
  }
}


.-bg {
	img {
		width: 100% !important;
		height: 100% !important;
		object-fit: cover;
		transition: 0.6s;
		object-position: center;
	}
}
.browser-course-wrapper:hover .browser-course-bg img {
	transform: scale(1.05);
}
.browser-course-wrapper {
	position: relative;
	border-radius: 5px;
	overflow: hidden;
}

.film{
  margin-right: 10px;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.browser-course-bg {
	position: absolute;
  	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.course-height {
	min-height: 270px;
	padding: 50px 40px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 4px;
	@media (max-width: 575px) {
		padding: 50px 25px;
	}
}

.browser-course-content {
	position: relative;
	width: 80%px;
	z-index: 1;
	@media (max-width: 575px){
		width: 260px;
	}
	span {
		margin-bottom: 10px;
		display: block;
		color: #000;
		font-weight: 700;
		@media (max-width: 575px) {
			left: 0;
		}
	}
}

.browser-course-tittle {
	a {
		font-size: 24px;
		color: #000;
		font-weight: 700;
		line-height: 1.3;
		margin-bottom: 30px;
		display: block;
	}
}

ul.category-list {
  margin-bottom: 0;
  padding: 0 0 15px;
  height: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  list-style-type: none;
}

ul.category-list li {
  margin-bottom: 15px;
}

ul.category-list li a,
ul.category-list li span {
  display: inline-block;
  color: $white-color;
  text-decoration: none;
  font-size: 15px;
  transition: $transition;
}

ul.category-list li a:hover {
  color: $primary-color;
}

.image-gallery  .image-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  padding: 100px;
  overflow: auto;
  background-color: rgba(0,0,0,0.5);
}
.image-gallery  .image-modal .modal-inner {
  background: #ffffff;
  padding: 20px;
}

.browser-btn{
  width: 150px !important;
  color: $primary-color;
  text-align: center;
  a{
    color: #000;
  }
}

/*=====================================================*/
/* About Us Page */
/*=====================================================*/
.year {
  font-size: 66px;
  margin-bottom: 0;
  opacity: 0.1;
  color: $primary-color;
}

@media only screen and (min-width: 768px) {
  .year {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: absolute;
    top: 50px;
    right: -30px;
    font-size: 96px;
  }
}

/*==========================================================*/
/* MINI SLIDER NEWS */
/*==========================================================*/

.mini-slider-news{
  background-color: #F6F8FB;
  box-shadow: 0px 4px 11.199999809265137px 0px #00000033;
}

.mini-slider-news .marquee{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  color: #000;
  justify-content: center;
  align-items: center;
  :hover{
    color: #2563EB;
  }
}

/*==========================================================*/
/* QL EDITOR STYLES */
/*==========================================================*/
.ql-align-justify {
	text-align: justify;
}
.ql-align-left {
	text-align: left;
}
.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.entry-description img {
	width: 100%;
}

/*==========================================================*/
/* FOOTER */
/*==========================================================*/

.footer-desc{
  color: #363636;
  font-weight: 300;
  margin-top: 10px;
  font-size: 16px;
}

.capitalize{
  text-transform: capitalize !important;
}

.footer-title{
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.main-slider{
  border-radius: 8px;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 991px) {
	.banner-content {max-width: 670px; padding: 0 15px;}
  	.banner-item .title {font-size: 35px; line-height: 35px; margin-bottom: 10px;}

}


@media only screen and (max-width: 1000px) {
	.banner-item {display: none;}
	.play-button{
		display: flex;
		justify-content: center;
	}
  }

.event-status-active{
	background-color: $primary-color;
	color: white;
	margin: 5px;
	width: 140px;
	border-radius: 5px;
	font-size: 12px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.event-status-notactive{
	background-color: #e1e0e0;
	width: 140px;
	color: #000;
	margin: 5px;
	border-radius: 5px;
	font-size: 12px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}