@import "../../assets/scss/variable.scss";

/*----------------------------------------------------*/
/* Category Icons */
/*----------------------------------------------------*/
.category-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  position: relative;
}
.category-icons:before {
  content: "";
  background: #ffffff;
  width: 110%;
  height: 110%;
  display: inline-block;
  position: absolute;
  left: -5%;
  top: -5%;
  z-index: 1;
  opacity: 0;
  -webkit-transform: scale(0.96);
  transform: scale(0.96);
  transition: $transition-3;
}
.category-icons:hover:before {
  -webkit-box-shadow: 5px 5px 24px 0px rgba(2, 45, 98, 0.1);
  box-shadow: 5px 5px 24px 0px rgba(2, 45, 98, 0.1);
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.category-icons .category-item .item-icon {
  color: $title-color;
  font-size: 44px;
  // width: 44px;
  height: 44px;
  line-height: 1;
  margin-bottom: 30px;
  position: relative;
  z-index: 2;
  transition: $transition-3;
  
}
.category-icons .category-item .category-title {
  color: $title-color;
  font-weight: bold;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
  font-size: 16px;
  text-decoration: none;
  transition: $transition-3;
}
.category-icons:hover .category-item .item-icon,
.category-icons:hover .category-item .category-title {
  color: $primary-color;
}

/*--------------------------------*/
/* Category Box Shadow */
/*--------------------------------*/
.category-wrapper {
  background: #ffffff;
  box-shadow: 5px 5px 24px 0px rgba(2, 45, 98, 0.1);
  border-radius: 5px;
}
 .show-more-cat {
  display: inline-flex;
  justify-content: center;
  margin: 60px 0 -20px 0;
  width: 100%;
}
 .show-more-cat a {
  color: $title-color;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
}
 .show-more-cat i {
  margin: 5px auto 0 auto;
}
 .show-more-cat .icon-btn {
  display: flex;
  width: 40px;
  height: 40px;
  text-align: center;
  background: $primary-color;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: $white-color;
  border-radius: 50%;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 991px) {

  .category-icons {padding: 20px}
  .category-icons:before {content: normal;}
}

@media only screen and (max-width: 575px) {

  .category-icons .category-item .category-title {font-size: 14px; line-height: 22px;}
}