@import "../../assets/scss/variable.scss";

.site-footer {
    font-size: 15px;
}
.site-footer .footer-main {
    padding: 100px 0;
    color: $title-color;
}
.site-footer hr {
    border-top-color: #e8e8e8;
    opacity: 0.9;
}

/*-----------------------*/
/* Footer Main */
/*-----------------------*/
.site-footer .widget {
    margin-bottom: 0px;
}
.site-footer .widget + .widget {
    margin-top: 30px;
}

.contact-component{
    display: flex;
    flex-direction: column;
    gap: 10px;
    a, div{
        color: #333333;
        font-family: 'Rubik';
        font-weight: 400;
    }
}

.send-email{
    div{
    height: 48px;
    position: relative;
    }
    input{
        height: 100%;
        width: 100%;
        outline: none;
        &:focus,&:active{
            outline: none;
        }
    }
    img{
        position: absolute;
        right: 5px;
    }
}

/*-----------------------*/
/* Footer Copyright */
/*-----------------------*/
.footer-copyright {
    background-color: $primary-color;
    padding: 10px 0;
    text-align: left !important;
}
.footer-copyright .copyright-text, .footer-copyright .copyright-text a {
    color: #fff;
}
.footer-copyright .widget-social-info {
    display: inline-block;
}

/*----------------------------------------*/
/* Footer Style 2 */
/*----------------------------------------*/
.site-footer.footer-style-01 .footer-main {
    padding: 60px 0;
}

.logo-bar{
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    align-items: center;
    .image-container{
        img{
            height: 30px;

            @media only screen and (max-width: 450px) {
                height: 20px;
            }

        }
        .logo-title-ed{
            @media only screen and (max-width: 400px) {
                display: none;
            }
        }
        
    }
    .image-container .logo-3{
        height: 38px !important;
    }
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
    }
}


.logo-link div{
    line-height: 20px;
    color: #000;
    transition: all 300ms ease-in-out;
}

.logo-link:hover div{
    color: $primary-color;
}

.logo-link img{
    width: 60px;
    height: 60px !important;
}

/*----------------------------------------*/
/* Footer Style 3 */
/*----------------------------------------*/
.footer-top-minus {
    margin-top: -3rem;
}
.footer-top-minus .footer-inner {
    margin-top: 3rem;
}
.site-footer.footer-style-03 .footer-main {
    padding: 100px 0;
}
.site-footer.footer-style-03 .footer-copyright {
    background-color: inherit;
    padding: 0;
    border: 0;
}

/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media only screen and (max-width: 991px) {
    .site-footer.footer-style-03 .footer-main {
        padding: 70px 0;
    }
}

@media only screen and (max-width: 767px) {
    .site-footer.footer-style-03 .footer-main {
        padding: 50px 0;
    }
}