@import "../../assets/scss/variable.scss";

/*--------------------------------------------*/
/* Video Button */
/*--------------------------------------------*/
.video-popup {
    position: relative;
}

.video-popup .img-fluid{
    // object-fit: cover;
    // height: 303px;
    position: relative;
}
.video-btn {
    position: absolute;
    z-index: 1;
    top: 50%;
	left: 50%;
	margin: auto;
    width: 55px !important;
    height: 68px !important;
    border-radius: 100% !important;
	border: none;
    background: rgba(0, 0, 0, 0.55);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.video-popup .video-btn:before {
    content: "";
    position: absolute;
    z-index: -9;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    width: 85px;
    height: 85px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 100% !important;
    -webkit-animation: pulse-border 1500ms ease-out infinite;
    animation: pulse-border 1500ms ease-out infinite;
}
.video-popup .video-btn i {
    color: #fff;
    font-size: 20px;
    transition: $transition-3;
}

.video-popup .video-btn:hover i {
    color: $white-color;
}

/* Video Style 2 */
.video-popup.video-style-2 .video-btn {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0px, 0px);
}

/* keyframes */
@keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
  
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
}

/*--------------------------------------------*/
/* Video PopUp */
/*--------------------------------------------*/
.pgs-video-popup.modal-md {
    max-width: 900px;
}
.pgs-video-popup .modal-header {
    padding: 0;
    margin: 0;
    border: 0;
}
.pgs-video-popup .btn-close {
    position: absolute;
    right: 0;
    top: -25px;
    opacity: 0.8;
    filter: brightness(0) invert(1);
}
.pgs-video-popup .modal-body {
    padding: 0;
    overflow: hidden;
    background-image: url(../../assets/images/loader.svg);
    background-repeat: no-repeat;
    background-size: 50px;
    background-position: center center;
    border-radius: $radius-5;
}


/*==========================================================*/
/* Responsive CSS */
/*==========================================================*/
@media (max-width: 1199px) {

}

.features-video-area {
	position: relative;
	margin-top: -220px;
}
.features-shape-wrapper-1 {
	position: absolute;
	bottom: 9%;
	left: -14%;
	line-height: 18px;
	span {
		color: #000;
		font-weight: 700;
	}
	@media only screen and (min-width: 768px) and (max-width: 991px) {
		left: -20%;
	}
	@media only screen and (min-width: 576px) and (max-width: 767px) {
		display: none;
	}
	@media (max-width: 575px) {
		display: none;
	}
}
.features-shape {
	position: absolute;
	top: -94px;
	right: 0;
}
.features-shape-werapper-2 {
	position: absolute;
	right: -12%;
	top: 25%;
	line-height: 18px;
	@media only screen and (min-width: 768px) and (max-width: 991px) {
		right: -20%;
	}
	@media only screen and (min-width: 576px) and (max-width: 767px) {
		display: none;
	}
	@media only screen and (max-width: 575px) {
		display: none;
	}
	span {
		color: #000;
		font-weight: 600;
	}
	img {
		position: absolute;
		bottom: -95px;
		right: 38px;
	}
}
.features-video-wrapper {
	padding: 20px;
	box-shadow: 0px 20px 40px rgba(24, 44, 74, 0.1);
	background: #fff;
	position: relative;
	border-radius: 10px;
}
.reatures-video-thumb {
	position: relative;
	img {
		width: 100%;
	}
	&::before {
		position: absolute;
		content: "";
		background: #020f29;
		opacity: 0.6;
		height: 100%;
		width: 100%;
	}
}
.features-video-content {
	display: inline-flex;
	position: absolute;
	top: 50%;
	left: 50%;
	right: 0;
	transform: translate(-50%, -50%);
	align-items: center;
	justify-content: center;
	margin-right: -50%;
    z-index: 10;
}
